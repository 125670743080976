import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import "firebase/firestore";

firebase.initializeApp({
  apiKey: "AIzaSyAw5cLxd_sz3KDbii12gIrogwD2lgn6CNI",
  authDomain: "dump-tabs.firebaseapp.com",
  projectId: "dump-tabs",
  storageBucket: "dump-tabs.appspot.com",
  messagingSenderId: "957376637832",
  appId: "1:957376637832:web:d6d7f722cbadf71075a83d",
  measurementId: "G-JT342N4PDP",
});
